import React from "react"
import { Link } from "gatsby"

const Responsible = () => {
    return (
    <div>
        <p>vokabox</p>
        <p><strong>E-Mail-Adresse: </strong> <a href="mailto:contact@vokabox.com">contact@vokabox.com</a></p>
        <p><strong>Kontaktformular: </strong>
        <Link to="/contact" title={"Kontaktformular"} target="_blank" rel="noopener noreferrer">Kontaktformular</Link></p>
        <p><strong>Impressum: </strong>
        <Link to="/terms" title={"Impressum"} target="_blank" rel="noopener noreferrer">Impressum</Link></p>
    </div>
    );
}

export default Responsible